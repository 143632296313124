<template>
  <div>
    <div class="d-flex justify-content-end pb-3">
      <div class="d-flex">
        <b-button
          v-if="multipleEdit"
          class="ml-auto mr-2"
          variant="success"
          @click="saveMultiple"
        >
          <i class="flaticon2-check-mark"></i> Save
        </b-button>

        <b-button
          v-if="!multipleEdit"
          class="ml-auto mr-2"
          variant="success"
          @click="setMultipleEdit"
        >
          <i class="flaticon-edit"></i> Multiple Edit
        </b-button>

        <b-button
          v-if="multipleEdit"
          class="ml-auto mr-2"
          variant="danger"
          @click="setCancelMultipleEdit"
        >
          <i class="flaticon-close"></i> Cancel Multiple Edit
        </b-button>

        <b-button class="ml-auto" variant="success" @click="loadDesigns">
          Load Design
        </b-button>

        <input
          type="file"
          class="d-none"
          ref="uploadExcel"
          @change="handleUploadExcel"
        />

        <b-dropdown right variant="success" class="ml-2">
          <template #button-content>
            <i class="fa fa-file-excel" /> Excel
          </template>

          <b-dropdown-item>
            <a class="d-inline text-dark" @click="uploadExcel">
              <i class="flaticon-upload-1 mr-2"></i> Upload
            </a>
          </b-dropdown-item>

          <vue-excel-xlsx
            :data="excelData"
            :columns="exportExcelColumn"
            :file-name="fileNameExcel"
            :sheetname="'sheet1'"
          >
            <b-dropdown-item>
              <div class="d-inline">
                <i class="flaticon-download mr-2"></i> Download
              </div>
            </b-dropdown-item>
          </vue-excel-xlsx>
        </b-dropdown>
      </div>
    </div>

    <complete-table
      :loading="loading"
      :data="multipleEdit ? cloneItems : items"
    >
      <template #header>
        <tr class="text-left">
          <th style="min-width: 350px;" class="pl-7">
            Aksi
          </th>
          <th style="min-width: 250px;" class="pl-7">
            <span class="text-dark-75">Numbering</span>
          </th>
          <th style="min-width: 300px;">Title</th>
          <th style="min-width: 200px;">Status</th>
          <th style="min-width: 100px;">Revision</th>
          <th style="min-width: 100px;">Version</th>
          <th style="min-width: 200px;">Date Receive</th>
          <th style="min-width: 250px;">Note</th>
          <th style="min-width: 200px;">Storage Doc</th>
          <th style="min-width: 100px;">Server</th>
          <th style="min-width: 100px;">Asbuilt</th>
          <th style="min-width: 200px;">Return Item</th>
          <th style="min-width: 150px;">Outstanding</th>
        </tr>
      </template>

      <template #defaultBody="{ item, i, number }" style="">
        <tr v-if="checkObseletet(i)">
          <td colspan="10">
            <div class="text-center">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden"></span>
              </div>
            </div>
          </td>
        </tr>
        <tr v-bind:key="i" v-if="!checkObseletet(i)">
          <td>
            <div class="d-flex justify-content-center">
              <template v-if="!multipleEdit">
                <a
                  class="btn btn-default font-weight-bolder font-size-sm mr-2"
                  @click="setExpandedIndex(i)"
                >
                  <i class="flaticon-eye"></i>
                </a>

                <on-edit-row :is-edit="isEdit(item.id)">
                  <template #onEdit>
                    <a
                      class="btn btn-light-danger font-weight-bolder font-size-sm mr-2"
                      @click="cancelEdit"
                    >
                      <i class="flaticon2-cancel-music"></i>
                    </a>
                    <a
                      class="btn btn-light-success font-weight-bolder font-size-sm"
                      @click="save"
                    >
                      <i class="flaticon2-check-mark"></i>
                    </a>
                  </template>

                  <template #onView>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <a
                          class="btn btn-light-primary font-weight-bolder font-size-sm mr-2"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          @click="obsolete(item, i)"
                        >
                          <i class="flaticon-refresh"></i>
                        </a>
                      </template>
                      <span>Reload</span>
                    </v-tooltip>

                    <a
                      class="btn btn-light-success font-weight-bolder font-size-sm mr-2"
                      @click="editData(item)"
                    >
                      <i class="flaticon-edit"></i>
                    </a>

                    <a
                      class="btn btn-light-danger font-weight-bolder font-size-sm mr-2"
                      @click="openDeleteDialog(item)"
                    >
                      <i class="flaticon2-rubbish-bin-delete-button"></i>
                    </a>

                    <a
                      class="btn btn-secondary font-weight-bolder font-size-sm mr-2"
                      @click="openHistoryModal(item)"
                    >
                      <i class="flaticon-list-2"></i>
                    </a>
                  </template>
                </on-edit-row>
              </template>

              <template v-if="multipleEdit">
                <on-edit-row :is-edit="isEdit(item.id)">
                  <template #onEdit>
                    <a
                      class="btn btn-light-danger font-weight-bolder font-size-sm mr-2 cancelMultipleButton"
                      @click="cancelMultipleEditData(item, i)"
                    >
                      <i class="flaticon2-cancel-music"></i>
                    </a>

                    <a
                      class="btn btn-light-success font-weight-bolder font-size-sm"
                      @click="confirmMultipleData(item, i, number)"
                    >
                      <i class="flaticon2-check-mark"></i>
                    </a>
                  </template>

                  <template #onView>
                    <a
                      class="btn btn-light-success font-weight-bolder font-size-sm mr-2"
                      @click="editMultipleData(item)"
                    >
                      <i class="flaticon-edit"></i>
                    </a>
                  </template>
                </on-edit-row>
              </template>
            </div>
          </td>

          <td class="pl-0">
            <span
              class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
            >
              {{ item.numbering }}
            </span>
          </td>

          <td>
            <on-edit-row :is-edit="isEdit(item.id)">
              <template #onEdit>
                <input
                  v-if="!multipleEdit"
                  class="form-control"
                  v-model="currentEditForm.title"
                />

                <input
                  v-if="multipleEdit"
                  class="form-control"
                  v-model="item.edit_form.title"
                />
              </template>

              <template #onView>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.title }}
                </span>
              </template>
            </on-edit-row>
          </td>

          <td>
            <on-edit-row :is-edit="isEdit(item.id)">
              <template #onEdit>
                <b-form-select
                  v-model="currentEditForm.status"
                  :options="formattedStatusOptions"
                  size="lg"
                  v-if="!multipleEdit"
                >
                </b-form-select>

                <b-form-select
                  v-model="item.edit_form.status"
                  :options="formattedStatusOptions"
                  size="lg"
                  v-if="multipleEdit"
                >
                </b-form-select>
              </template>

              <template #onView>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ optionalObject(item.last_status, "code") }}
                </span>
              </template>
            </on-edit-row>
          </td>

          <td>
            <on-edit-row :is-edit="isEdit(item.id)">
              <template #onEdit>
                <input
                  class="form-control"
                  v-model="currentEditForm.revision_version"
                  v-if="!multipleEdit"
                />

                <input
                  class="form-control"
                  v-model="item.edit_form.revision_version"
                  v-if="multipleEdit"
                />
              </template>

              <template #onView>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.revision_version }}
                </span>
              </template>
            </on-edit-row>
          </td>

          <td>
            <on-edit-row :is-edit="isEdit(item.id)">
              <template #onEdit>
                <input
                  v-if="!multipleEdit"
                  class="form-control"
                  v-model="currentEditForm.version"
                />

                <input
                  v-if="multipleEdit"
                  class="form-control"
                  v-model="item.edit_form.version"
                />
              </template>

              <template #onView>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.version }}
                </span>
              </template>
            </on-edit-row>
          </td>

          <td>
            <on-edit-row :is-edit="isEdit(item.id)">
              <template #onEdit>
                <b-input-group>
                  <input
                    size="lg"
                    class="form-control"
                    type="text"
                    v-model="currentEditForm.date_receive"
                    v-if="!multipleEdit"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      size="md"
                      button-only
                      right
                      v-model="currentEditForm.date_receive"
                      reset-button
                      reset-value=""
                      v-if="!multipleEdit"
                      :no-flip="true"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>

                <b-input-group>
                  <input
                    class="form-control"
                    type="text"
                    size="lg"
                    v-model="item.edit_form.date_receive"
                    v-if="multipleEdit"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      size="md"
                      button-only
                      right
                      v-model="item.edit_form.date_receive"
                      reset-button
                      reset-value=""
                      v-if="multipleEdit"
                      :no-flip="true"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </template>

              <template #onView>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ 
                    moment(item.date_receive).isValid() ?
                    moment(item.date_receive).format("DD-MM-YYYY") : "" }}
                </span>
              </template>
            </on-edit-row>
          </td>

          <td>
            <on-edit-row :is-edit="isEdit(item.id)">
              <template #onEdit>
                <input
                  v-if="!multipleEdit"
                  class="form-control"
                  v-model="currentEditForm.delay"
                />

                <input
                  v-if="multipleEdit"
                  class="form-control"
                  v-model="item.edit_form.delay"
                />
              </template>

              <template #onView>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.delay }}
                </span>
              </template>
            </on-edit-row>
          </td>

          <td>
            <on-edit-row :is-edit="isEdit(item.id)">
              <template #onEdit>
                <input
                  class="form-control"
                  v-model="currentEditForm.doc_room"
                  v-if="!multipleEdit"
                />

                <input
                  class="form-control"
                  v-model="item.edit_form.doc_room"
                  v-if="multipleEdit"
                />
              </template>

              <template #onView>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.doc_room }}
                </span>
              </template>
            </on-edit-row>
          </td>

          <td>
            <on-edit-row :is-edit="isEdit(item.id)">
              <template #onEdit>
                <button
                  class="btn btn-primary btn-md"
                  @click="browseFile(item)"
                >
                  Browse
                </button>
              </template>

              <template #onView>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  <a
                    v-if="item.server != '0' && item.server != ''"
                    class="btn btn-light-primary font-weight-bolder font-size-sm ml-2"
                    @click="downloadFile(item.server, item.title)"
                    ><i class="flaticon-download"></i> Download</a
                  >
                </span>
              </template>
            </on-edit-row>
          </td>

          <td>
            <on-edit-row :is-edit="isEdit(item.id)">
              <template #onEdit>
                <b-form-checkbox
                  class="mx-auto"
                  size="lg"
                  v-model="currentEditForm.monitoring_asbuilt_status"
                  v-if="!multipleEdit"
                ></b-form-checkbox>

                <b-form-checkbox
                  class="mx-auto"
                  size="lg"
                  v-model="item.edit_form.monitoring_asbuilt_status"
                  v-if="multipleEdit"
                ></b-form-checkbox>
              </template>

              <template #onView>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.monitoring_asbuilt_status }}
                </span>
              </template>
            </on-edit-row>
          </td>

          <td>
            <on-edit-row :is-edit="isEdit(item.id)">
              <template #onEdit>
                <input
                  class="form-control"
                  v-model="currentEditForm.return_item"
                  v-if="!multipleEdit"
                />

                <input
                  class="form-control"
                  v-model="item.edit_form.return_item"
                  v-if="multipleEdit"
                />
              </template>

              <template #onView>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.return_item }}
                </span>
              </template>
            </on-edit-row>
          </td>

          <td>
            <on-edit-row :is-edit="isEdit(item.id)">
              <template #onEdit>
                <b-form-select
                  v-model="currentEditForm.outstanding"
                  :options="formattedOutstandingOptions"
                  size="lg"
                  v-if="!multipleEdit"
                ></b-form-select>

                <b-form-select
                  v-model="item.edit_form.outstanding"
                  :options="formattedOutstandingOptions"
                  size="lg"
                  v-if="multipleEdit"
                ></b-form-select>
              </template>

              <template #onView>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.outstanding ? item.outstanding.name : null }}
                </span>
              </template>
            </on-edit-row>
          </td>
        </tr>

        <tr v-bind:key="i + 'detail'" v-if="expandedIndex === i">
          <td colspan="12">
            <template>
              <div>
                <div class="card p-5 gutter-b">
                  <div class="card-body p-0">
                    <div class="row">
                      <div class="col-md-3">
                        <span
                          class="text-dark-75 font-weight-bolder font-size-lg"
                          >Type : </span
                        ><span class="text-dark-75">{{
                          optionalObject(item.type, "name")
                        }}</span>
                      </div>

                      <div class="col-md-3">
                        <span
                          class="text-dark-75 font-weight-bolder font-size-lg"
                        >
                          Station :
                        </span>

                        <span class="text-dark-75">
                          {{ optionalObject(item.station, "name") }}
                        </span>
                      </div>

                      <div class="col-md-3">
                        <span
                          class="text-dark-75 font-weight-bolder font-size-lg"
                          >Approved : </span
                        ><span class="text-dark-75">{{ item.approved }}</span>
                      </div>

                      <div class="col-md-3">
                        <span
                          class="text-dark-75 font-weight-bolder font-size-lg"
                          >Planning Date : </span
                        ><span class="text-dark-75">{{
                          moment(item.planning_date).isValid() ?
                          moment(item.planning_date).format("DD-MM-YYYY") : ""
                        }}</span>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-3">
                        <span
                          class="text-dark-75 font-weight-bolder font-size-lg"
                          >Category : </span
                        ><span class="text-dark-75">{{
                          optionalObject(item.category, "name")
                        }}</span>
                      </div>

                      <div class="col-md-3">
                        <span
                          class="text-dark-75 font-weight-bolder font-size-lg"
                          >Designer PIC : </span
                        ><span class="text-dark-75">
                          {{ optionalObject(item.designer_pic, "code") }}</span
                        >
                      </div>

                      <div class="col-md-3">
                        <span
                          class="text-dark-75 font-weight-bolder font-size-lg"
                          >Drafter PIC : </span
                        ><span class="text-dark-75">
                          {{ optionalObject(item.drafter_pic, "code") }}</span
                        >
                      </div>

                      <div class="col-md-3">
                        <span
                          class="text-dark-75 font-weight-bolder font-size-lg"
                          >Checker PIC : </span
                        ><span class="text-dark-75">
                          {{ optionalObject(item.checker_pic, "code") }}</span
                        >
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-3">
                        <span
                          class="text-dark-75 font-weight-bolder font-size-lg"
                          >Scope : </span
                        ><span class="text-dark-75">{{
                          optionalObject(item.scope, "name")
                        }}</span>
                      </div>

                      <div class="col-md-3">
                        <span
                          class="text-dark-75 font-weight-bolder font-size-lg"
                          >Designer Hour : </span
                        ><span class="text-dark-75">
                          {{ item.designer_hour }}</span
                        >
                      </div>

                      <div class="col-md-3">
                        <span
                          class="text-dark-75 font-weight-bolder font-size-lg"
                          >Drafter Hour : </span
                        ><span class="text-dark-75">{{
                          item.drafter_hour
                        }}</span>
                      </div>

                      <div class="col-md-3">
                        <span
                          class="text-dark-75 font-weight-bolder font-size-lg"
                          >Checker Hour : </span
                        ><span class="text-dark-75">{{
                          item.checker_hour
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </td>
        </tr>
      </template>
    </complete-table>

    <v-dialog v-model="showSelectServer">
      <v-card>
        <v-card-title>
          {{ `Pilih server (${selectedServerName})` }}
          <input
            size="lg"
            class="form-control"
            type="text"
            v-model="selectedFullPath"
          />
        </v-card-title>
        <v-card-text v-if="!uploadLoading">
          <v-file-input
            show-size
            :disabled="!selectedServerName"
            label="File input"
            @change="uploadFile"
          ></v-file-input>

          <v-treeview
            :items="servers"
            selection-type="leaf"
            activatable
            item-key="id"
            @update:active="selectServer"
            return-object
          >
            <template v-slot:prepend="{ item, open }">
              <v-icon v-if="!item.file">
                {{ open ? "mdi-folder-open" : "mdi-folder" }}
              </v-icon>

              <v-icon v-else>
                {{ "mdi-folder" }}
              </v-icon>
            </template>
          </v-treeview>
        </v-card-text>

        <v-card-text v-else>
          <div class="text-center">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden"></span>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="showSelectServer = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">Warning</v-card-title>

        <v-card-text>
          Are You Sure Want To Delete Design
          <b>{{ this.currentItem ? this.currentItem.numbering : null }}</b> ?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="deleteItem">
            Delete
          </v-btn>

          <v-btn color="green darken-1" text @click="dialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="historyModal"
      transition="dialog-top-transition"
      max-width="1000"
    >
      <v-card>
        <v-card-title class="headline">History</v-card-title>

        <v-card-text>
          <complete-table :loading="historyLoading" :data="histories">
            <template #header>
              <tr class="text-left">
                <th></th>
                <th style="min-width: 200px;">Update Date</th>
                <th style="min-width: 250px;" class="pl-7">
                  <span class="text-dark-75">Numbering</span>
                </th>
                <th style="min-width: 300px;">Title</th>
                <th style="min-width: 200px;">Status</th>
                <th style="min-width: 100px;">Revision</th>
                <th style="min-width: 100px;">Version</th>
                <th style="min-width: 200px;">Date Receive</th>
                <th style="min-width: 250px;">Note</th>
                <th style="min-width: 200px;">Storage Ruang Doc</th>
                <th style="min-width: 100px;">Server</th>
                <th style="min-width: 100px;">Asbuilt</th>
                <th style="min-width: 150px;">Return Item</th>
                <th style="min-width: 150px;">Outstanding</th>
                <th style="min-width: 150px;">Deadline</th>
                <th style="min-width: 150px;">Transmittal Number</th>
                <th style="min-width: 150px;">Revision SHD</th>
                <th style="min-width: 150px;">Completed Date</th>
                <th style="min-width: 150px;">Planning Date</th>
                <th style="min-width: 250px;">Designer PIC</th>
                <th style="min-width: 150px;">Designer Hour</th>
                <th style="min-width: 250px;">Drafter PIC</th>
                <th style="min-width: 150px;">Drafter Hour</th>
                <th style="min-width: 250px;">Checker PIC</th>
                <th style="min-width: 150px;">Checker Hour</th>
              </tr>
            </template>

            <template #defaultBody="{ item, i }">
              <tr v-bind:key="i" :style="{'background-color': item.latest ? 'lightblue' : 'white'}">
                <td>
                  <button
                    class="btn btn-default btn-xs"
                    @click="setExpandedIndexDetail(i)"
                  >
                    <span class="flaticon-eye"></span>
                  </button>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ 
                      moment(item.created_at).isValid() ?
                      moment(item.created_at).format("DD-MM-YYYY") : "" }}
                  </span>
                </td>

                <td class="pl-0">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.numbering }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.title }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ optionalObject(item.last_status, "code") }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.revision_version }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.version }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ 
                      moment(item.date_receive).isValid() ?
                      moment(item.date_receive).format("DD-MM-YYYY") : "" }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.delay }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.doc_room }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.server }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.monitoring_asbuilt_status }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.return_item }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.outstanding ? item.outstanding.name : null }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.deadline }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.transmittal_number }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.revision_shd }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.completed_date }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.planning_date }}
                  </span>
                </td>
                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{
                      item.designer_pic != null ? item.designer_pic.name : ""
                    }}
                  </span>
                </td>
                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.designer_hour }}
                  </span>
                </td>
                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.drafter_pic != null ? item.drafter_pic.name : "" }}
                  </span>
                </td>
                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.drafter_hour }}
                  </span>
                </td>
                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.checker_pic != null ? item.checker_pic.name : "" }}
                  </span>
                </td>
                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.checker_hour }}
                  </span>
                </td>
                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.completed_date }}
                  </span>
                </td>
              </tr>

              <tr
                v-bind:key="i + 'detail-modal'"
                v-if="expandedIndexDetail === i"
              >
                <td colspan="12">
                  <template>
                    <div>
                      <div class="card p-5 gutter-b">
                        <div class="card-body p-0">
                          <div class="row">
                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Type : </span
                              ><span class="text-dark-75">{{
                                optionalObject(item.type, "name")
                              }}</span>
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                              >
                                Station :
                              </span>

                              <span class="text-dark-75">
                                {{ optionalObject(item.station, "name") }}
                              </span>
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Approved : </span
                              ><span class="text-dark-75">{{
                                item.approved
                              }}</span>
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Planning Date : </span
                              ><span class="text-dark-75">{{
                                item.planning_date
                              }}</span>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Category : </span
                              ><span class="text-dark-75">{{
                                optionalObject(item.category, "name")
                              }}</span>
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Designer PIC : </span
                              ><span class="text-dark-75">
                                {{
                                  optionalObject(item.designer_pic, "code")
                                }}</span
                              >
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Drafter PIC : </span
                              ><span class="text-dark-75">
                                {{
                                  optionalObject(item.drafter_pic, "code")
                                }}</span
                              >
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Checker PIC : </span
                              ><span class="text-dark-75">
                                {{
                                  optionalObject(item.checker_pic, "code")
                                }}</span
                              >
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Scope : </span
                              ><span class="text-dark-75">{{
                                optionalObject(item.scope, "name")
                              }}</span>
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Designer Hour : </span
                              ><span class="text-dark-75">
                                {{ item.designer_hour }}</span
                              >
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Drafter Hour : </span
                              ><span class="text-dark-75">{{
                                item.drafter_hour
                              }}</span>
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Checker Hour : </span
                              ><span class="text-dark-75">{{
                                item.checker_hour
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </td>
              </tr>
            </template>
          </complete-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="historyModal = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CompleteTable from "../../../content/widgets/advance-table/CompleteTable";
import {
  DELETE_DL_DESIGN,
  UPDATE_DL_DESIGN,
} from "../../../../core/services/store/dl.design.module";
import OnEditRow from "../../../content/widgets/advance-table/row/OnEditRow";
import { mapState } from "vuex";
import { GET_OUTSTANDING } from "../../../../core/services/store/outstanding.module";
import { optionalData } from "../../../../core/helper/object-validation.helper";
import { arrayMoreThanOne } from "../../../../core/helper/array-validation.helper";
import { GET_STATUS_OPTION } from "../../../../core/services/store/status.module";
import { exportExcelColumn } from "./detail.js";
import { excelToArray } from "@/core/helper/excel.helper";
import ApiService from "@/core/services/api.service";
import { showToast } from "@/core/helper/toast.helper";

export default {
  name: "TabContent",
  components: {
    OnEditRow,
    CompleteTable,
  },
  props: {
    loading: {
      default: true,
      type: Boolean,
    },
    items: {
      default: () => [],
      type: Array,
    },
    obseleteList: [],
    designType: {
      require: true,
      default: "DOC",
      type: String,
    },
    project: {
      required: false,
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      outstanding: (state) => state.outstanding.outstanding?.data,
      statuses: (state) => state.status.status?.data,
    }),

    formattedOutstandingOptions() {
      let outstanding = [];

      if (this.arrayMoreThanOne(this.outstanding)) {
        outstanding = this.outstanding.map((scope) => {
          return {
            value: scope.code,
            text: scope.name,
          };
        });
      }

      return [{ value: null, text: "Choose Outstanding" }, ...outstanding];
    },

    formattedStatusOptions() {
      let statuses = [];

      if (arrayMoreThanOne(this.statuses)) {
        statuses = this.statuses.map((project) => {
          return {
            value: project.code,
            text: project.name,
          };
        });
      }

      return [{ value: null, text: "Choose status" }, ...statuses];
    },

    excelData() {
      return this.items?.map((item) => {
        return {
          id: item?.id,
          title: item?.title,
          last_status: item?.last_status?.code,
          revision_version: item?.revision_version,
          version: item?.version,
          date_receive: item?.date_receive,
          delay: item?.delay,
          doc_room: item?.doc_room,
          server: item?.server,
          monitoring_asbuilt_status: item?.monitoring_asbuilt_status,
          return_item: item?.return_item,
          outstanding: item?.outstanding?.code,
          obselete: item?.obselete,
          numbering: item?.numbering,
          hapus: null,
        };
      });
    },
    fileNameExcel() {
      return (
        this.project.code +
        "-" +
        this.project.nickname +
        "-" +
        (this.designType == "DRW"
          ? "Drawing"
          : this.designType == "FOR"
          ? "Software"
          : "Document") +
        " Dl Design"
      );
    },
  },
  data() {
    return {
      uploadLoading: false,
      showSelectServer: false,
      selectedServerName: null,
      selectedFullPath: null,
      servers: [],
      excel: null,
      exportExcelColumn: exportExcelColumn,
      expandedIndex: null,
      expandedIndexDetail: null,
      currentItem: null,
      dialog: false,
      currentEditId: null,
      historyModal: false,
      historyLoading: true,
      histories: [],
      multipleCurrentEditId: [],
      multipleCurrentEditForm: [],
      obseleteData: [],
      currentEditForm: {
        title: null,
        status: null,
        revision_version: null,
        version: null,
        date_receive: null,
        delay: null,
        doc_room: null,
        server: null,
        monitoring_asbuilt_status: null,
        return_item: null,
        outstanding: "project",
        file: null,
      },
      multipleEdit: false,
      cloneItems: [],
      confirmedIds: [],
    };
  },
  mounted() {
    this.$store.dispatch(GET_OUTSTANDING, {});

    this.getStatus();

    // this.fetchServers();
  },
  watch: {
    obseleteList: function(data) {
      this.obseleteData = data;
    },
  },
  methods: {
    setCancelMultipleEdit() {
      var getDataMultipleButton = document.getElementsByClassName(
        "cancelMultipleButton"
      );
      for (var i = 0; i < getDataMultipleButton.length; i++) {
        getDataMultipleButton.item(i).click();
      }
      this.multipleEdit = false;
      this.currentItem = [];
      this.currentEditForm = {
        title: null,
        status: null,
        revision_version: null,
        version: null,
        date_receive: null,
        delay: null,
        doc_room: null,
        server: null,
        monitoring_asbuilt_status: null,
        return_item: null,
        outstanding: "project",
        file: null,
      };
      this.cloneItems = this.items;
    },
    checkObseletet(i) {
      if (this.obseleteData.length > 0) {
        return (
          this.obseleteData.find(
            (x) => x.type == this.designType && x.i == i
          ) != undefined
        );
      } else {
        return false;
      }
    },
    setMultipleEdit() {
      this.multipleEdit = true;

      this.cloneItems = this.items?.map((item) => {
        return {
          ...item,
          edit_form: {
            id: item.id,
            title: item.title,
            status: item.last_status?.code,
            revision_version: item.revision_version,
            version: item.version,
            date_receive: item.date_receive,
            delay: item.delay,
            doc_room: item.doc_room,
            server: item.server,
            monitoring_asbuilt_status: item.monitoring_asbuilt_status,
            return_item: item.return_item,
            outstanding: item.outstanding?.code,
            file: item.server,
          },
        };
      });
    },

    saveMultiple() {
      var checkNotconfirm = this.multipleCurrentEditForm.find(x => !x.confirm);
      if (checkNotconfirm != undefined) {
        showToast("Failed", "Confirm All Edited Data First !", "danger");
      } else {
        this.multipleCurrentEditForm.map((x) => x.edit_form);
        this.$store
          .dispatch(UPDATE_DL_DESIGN, {
            project: this.project,
            dld: this.multipleCurrentEditForm,
          })
          .then(() => {
            this.multipleEdit = false;

            this.cloneItems = [];
            this.multipleCurrentEditForm = [];
            this.multipleCurrentEditId = [];
            this.confirmedIds = [];

            this.loadDesigns();
          });
      }
    },

    cancelMultipleEdit() {
      const findIndex = this.cloneItems?.findIndex(
        (item) => item.id === this.currentItem.id
      );

      this.cloneItems[findIndex].edit_form = {
        title: this.currentItem.title,
        status: this.currentItem.last_status?.code,
        revision_version: this.currentItem.revision_version,
        version: this.currentItem.version,
        date_receive: this.currentItem.date_receive,
        delay: this.currentItem.delay,
        doc_room: this.currentItem.doc_room,
        server: this.currentItem.server,
        monitoring_asbuilt_status: this.currentItem.monitoring_asbuilt_status,
        return_item: this.currentItem.return_item,
        outstanding: this.currentItem.outstanding?.code,
      };

      this.currentEditId = null;

      this.currentItem = null;
    },

    confirmMultipleData(data, index, number) {

      var dataEdited = this.multipleCurrentEditForm.find((x) => x.id == data.id)
        .edit_form;
      var getData = this.cloneItems[number - 1];
      const outstanding = this.formattedOutstandingOptions.find(
        (item) => item.value === dataEdited.outstanding
      );
      if (typeof dataEdited.status !== "undefined") {
        const statusOptions = this.formattedStatusOptions.find(
          (item) => item.value === dataEdited.status
        );
        getData.last_status = {
          code: statusOptions.value,
          name: statusOptions.text,
        };
      }

      getData.title = dataEdited.title;

      getData.last_status_code = dataEdited.status;
      getData.revision_version = dataEdited.revision_version;
      getData.version = dataEdited.version;
      getData.date_receive = dataEdited.date_receive;
      getData.delay = dataEdited.delay;
      getData.doc_room = dataEdited.doc_room;
      getData.server = dataEdited.server;
      getData.monitoring_asbuilt_status = dataEdited.monitoring_asbuilt_status;
      getData.return_item = dataEdited.return_item;
      getData.outstanding = {
        code: outstanding?.value,
        drafter: outstanding?.text,
        name: outstanding?.text,
      };
      dataEdited = getData;
      dataEdited.confirm = true;
      
      this.multipleCurrentEditId = this.multipleCurrentEditId.filter(function(
        e
      ) {
        return e != data.id;
      });
    },

    editMultipleData(data) {
      this.currentItem = data;
      // this.currentEditId = data.id;
      if (!this.multipleCurrentEditId.includes(data.id)) {
        this.multipleCurrentEditId.push(data.id);
        data.confirm = false;
        this.multipleCurrentEditForm.push(data);
      }
    },
    cancelMultipleEditData(data, index) {
      this.cloneItems[index].edit_form = {
        title: data.title,
        status: data.last_status?.code,
        revision_version: data.revision_version,
        version: data.version,
        date_receive: data.date_receive,
        delay: data.delay,
        doc_room: data.doc_room,
        server: data.server,
        monitoring_asbuilt_status: data.monitoring_asbuilt_status,
        return_item: data.return_item,
      };
      this.multipleCurrentEditId = this.multipleCurrentEditId.filter(function(
        e
      ) {
        return e != data.id;
      });

      this.multipleCurrentEditForm = this.multipleCurrentEditForm.filter(function(
        e
      ) {
        return e.id != data.id;
      });
    },

    openHistoryModal(item) {
      this.historyLoading = true;

      ApiService.setHeader();

      ApiService.query(`project/dld/${item.id}/history/DLD/menu`)
        .then((response) => {
          this.histories = response.data.data;

          this.historyLoading = false;

          this.historyModal = true;
        })
        .catch(() => {
          this.historyModal = false;

          this.historyLoading = false;

          showToast("Info", "No Change In Current Design", "info");
        });
    },
    downloadFile(url, fileName) {
      ApiService.setHeader();

      ApiService.postDownload("ftp/download", {
        file_url: url,
      })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(
            new Blob([response?.data])
          );

          const contentDisposition = response.headers['content-disposition'];

          var filenameMatch = "";
          
          var regex = /filename="([^"]+)"/;
          var match = regex.exec(contentDisposition);
          if(match){
            filenameMatch = match[1];
          }

          const fileLink = document.createElement("a");
          console.log("file name ", fileName);
          fileLink.href = fileURL;
          // var SplitFileName = fileName.split("/");
          // var splitUrl = url.split(".");
          // var fileExt =
          //   splitUrl.length > 1 ? splitUrl[splitUrl.length - 1] : "xlsx";
          // var getFileName =
          //   SplitFileName.length > 0
          //     ? SplitFileName[SplitFileName.length - 1] + "." + fileExt
          //     : "-";

          fileLink.setAttribute("download", filenameMatch);

          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((error) => {
          console.log(error.message);
          showToast("Error", "Download Failed, Please Reupload File", "danger");
        });
    },
    uploadFile(file) {
      ApiService.setHeader();

      const formData = new FormData();

      // formData.append("id", this.currentEditId);
      formData.append("id", this.currentItem.id)

      formData.append("file", file);

      formData.append("ftp_id", this.currentEditForm.server);

      formData.append("path", this.selectedFullPath);

      this.uploadLoading = true;

      console.log('server sebelum upload ', this.currentEditForm.file);

      ApiService.post("project/dld/upload/path", formData)
        .then((response) => {
          showToast("Success", "Upload success", "success");

          this.showSelectServer = false;

          this.uploadLoading = false;

          this.currentEditForm.file = response.data.data?.server;

          this.multipleCurrentEditForm.find((x) => x.id == this.currentItem.id).server = this.currentEditForm.file;
        })
        .catch((error) => {
          this.uploadLoading = false;

          

          if(error.response.status == 409) {
            showToast("Error", error.response.data.message, "danger");
            console.log('server sesudah upload ', this.currentEditForm.file);
          }else{
            showToast("Error", "Upload failed", "danger");
          }
        });
    },

    selectServer(event) {

      if (!this.multipleEdit) {
        this.currentEditForm.server = event[0]?.id;

        this.selectedServerName = event[0]?.name;
        this.selectedFullPath = event[0]?.full_path;
      } else {
        const findIndex = this.items?.findIndex(
          (item) => item.id === 
          this.currentItem.id
        );

        this.currentEditForm.server = event[0]?.id;
        this.selectedServerName = event[0]?.name;
        this.selectedFullPath = event[0]?.full_path;

        this.cloneItems[findIndex].edit_form.server = event[0]?.full_path;
      }

      //isi current selected directory child
      //ditambahkan terlebih dahulu check kalau panjang children = 0 maka ditambahkan, jika sudah terisi, jangan ditambahkan
      if (this.project.code !== null) {
        let url = "ftp/folder/" + this.project.code + "/remote";
        ApiService.setHeader();
        ApiService.query(url,{
          params: {
            path: this.selectedFullPath
          }
        })
          .then((response) => {
            event[0].children =this.subMenu(response?.data?.data ?? []);
            event[0].expanded = true;
          })
          .catch(() => {
            showToast("Error", "Failed to fetch servers", "error");
          }); 
      }
    },

    fetchServers() {
      if (this.project.code !== null) {
        let url = "ftp/folder/" + this.project.code + "/remote";
        ApiService.setHeader();
        ApiService.query(url)
          .then((response) => {
            this.servers = this.subMenu(response?.data?.data ?? []);
          })
          .catch(() => {
            showToast("Error", "Failed to fetch servers", "error");
          }); 
      }
    },

    subMenu(listSubMenu) {
      let result = [];

      if (arrayMoreThanOne(listSubMenu)) {
        result = listSubMenu.map((menu) => {
          return {
            id: menu.name,
            name: menu.name,
            full_path: menu.full_path,
            children: this.subMenu(menu.children),
            expanded: false,
          };
        });
      }

      return result;
    },

    browseFile(item) {
      this.showSelectServer = true;
      // this.currentEditId = item.id;
      this.currentItem = item;
    },

    handleUploadExcel(event) {
      const file = event.target.files[0];

      excelToArray(file, this.postExcelData);
    },

    postExcelData(data) {
      this.$store
        .dispatch(UPDATE_DL_DESIGN, {
          project: this.project,
          dld: data?.map((item) => {
            const outstanding = this.formattedOutstandingOptions.find(
              (option) => option.value === item[12]
            );

            const lastStatus = this.formattedStatusOptions.find(
              (status) => status.value === item[3]
            );

            return {
              id: item[0] ?? null,
              title: item[1] ?? null,
              last_status: {
                code: lastStatus?.value ?? null,
                name: lastStatus?.text ?? null,
              },
              revision_version: item[4] ?? null,
              version: item[5] ?? null,
              date_receive: item[6] ?? null,
              delay: item[7] ?? null,
              doc_room: item[8] ?? null,
              server: item[9] ?? null,
              monitoring_asbuilt_status: item[10] ?? null,
              return_item: item[11] ?? null,
              outstanding: {
                code: outstanding?.value ?? null,
                drafter: outstanding?.text ?? null,
              },
              obselete: item[13] ?? null,
              hapus: item[14],
            };
          }),
        })
        .then(() => {
          this.loadDesigns();
        });
      var files = document.querySelectorAll("input[type=file]");
      files.forEach((element) => {
        element.value = "";
      });
    },

    uploadExcel() {
      this.$refs.uploadExcel.click();
    },

    setExpandedIndex(index) {
      if (this.expandedIndex === index) {
        this.expandedIndex = null;
      } else {
        this.expandedIndex = index;
      }
    },

    setExpandedIndexDetail(index) {
      if (this.expandedIndexDetail === index) {
        this.expandedIndexDetail = null;
      } else {
        this.expandedIndexDetail = index;
      }
    },

    openDeleteDialog(item) {
      this.currentItem = item;
      this.dialog = true;
    },

    optionalObject(object, key) {
      return optionalData(object, key);
    },

    toEditPage() {
      //
    },

    editData(item) {
      this.currentEditId = item.id;

      this.currentItem = item;

      this.currentEditForm = {
        title: item.title,
        status: item.last_status?.code,
        revision_version: item.revision_version,
        version: item.version,
        date_receive: item.date_receive,
        delay: item.delay,
        doc_room: item.doc_room,
        server: item.server,
        monitoring_asbuilt_status: item.monitoring_asbuilt_status,
        return_item: item.return_item,
        outstanding: item.outstanding?.code,
        file: item.server,
      };
    },

    cancelEdit() {
      this.currentEditId = null;

      this.currentItem = null;

      this.currentEditForm = {
        title: null,
        status: null,
        revision_version: null,
        version: null,
        date_receive: null,
        delay: null,
        doc_room: null,
        server: null,
        monitoring_asbuilt_status: null,
        return_item: null,
        outstanding: null,
        file: null,
      };
    },

    loadDesigns() {
      this.fetchServers();
      this.$emit("loadDesigns", this.designType);
    },

    obsolete(item, i) {
      this.$emit("obsolete", item, this.designType, i);
    },

    isEdit(id) {
      return (
        this.multipleCurrentEditId.includes(id) || id === this.currentEditId
      );
    },

    deleteItem() {
      this.dialog = false;

      this.$store.dispatch(DELETE_DL_DESIGN, {
        data: {
          project: {
            code: this.project?.code,
            nickname: this.project?.nickname,
          },
          designs: [this.currentItem.id],
        },
      });
    },

    save() {
      const outstanding = this.formattedOutstandingOptions.find(
        (item) => item.value === this.currentEditForm.outstanding
      );
      const lastStatus = this.formattedStatusOptions.find(
        (status) => status.value === this.currentEditForm.status
      );

      this.$store
        .dispatch(UPDATE_DL_DESIGN, {
          project: this.project,
          dld: [
            {
              id: this.currentEditId,
              title: this.currentEditForm.title ?? null,
              last_status: {
                code: lastStatus?.value ?? null,
                name: lastStatus?.text ?? null,
              },
              revision_version: this.currentEditForm.revision_version ?? null,
              version: this.currentEditForm.version ?? null,
              date_receive: this.currentEditForm.date_receive ?? null,
              delay: this.currentEditForm.delay ?? null,
              doc_room: this.currentEditForm.doc_room ?? null,
              server: this.currentEditForm.file ?? null,
              monitoring_asbuilt_status:
                this.currentEditForm.monitoring_asbuilt_status ?? null,
              return_item: this.currentEditForm.return_item ?? null,
              outstanding: {
                code: outstanding?.value ?? null,
                drafter: outstanding?.text ?? null,
              },
              obselete: 0,
            },
          ],
        })
        .then(() => {
          this.cancelEdit();

          this.loadDesigns();
        });
    },

    arrayMoreThanOne(array) {
      return array?.length > 0;
    },

    getStatus() {
      if (!arrayMoreThanOne(this.statuses)) {
        this.$store.dispatch(GET_STATUS_OPTION, {
          params: {},
        });
      }
    },
  },
};
</script>
