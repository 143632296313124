<template>
  <div data-app>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Nickname</label>

            <b-form-select
              v-model="filter.nickname"
              :options="formattedProjectOptions"
              @change="getStations($event)"
              size="lg"
            ></b-form-select>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Owner</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="selectedNickname.ownerName"
              disabled
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Project Name</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="selectedNickname.projectName"
              disabled
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Contract Number</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="selectedNickname.contractNumber"
              disabled
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Status</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="selectedNickname.status"
              disabled
            ></b-form-input>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Station</label>

            <b-form-select
              v-model="filter.station"
              :options="formattedStationOptions"
              size="lg"
            ></b-form-select>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Scope</label>

            <b-form-select
              v-model="filter.scope"
              :options="formattedScopeOptions"
              size="lg"
            ></b-form-select>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Title</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="filter.title"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Status</label>

            <b-form-select
              v-model="filter.status"
              :options="formatedStatusOptions"
              size="lg"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <b-tabs content-class="mt-3">
          <b-tab title="Document" @click="loadDesigns('DOC')" active>
            <tab-content
              :project="selectedNickname"
              :items="filteredData"
              :loading="loadingProjects"
              :obseleteList="obseleteData"
              design-type="DOC"
              @loadDesigns="loadDesigns"
              @obsolete="obsolete"
            />
          </b-tab>

          <b-tab title="Drawing" @click="loadDesigns('DRW')">
            <tab-content
              :project="selectedNickname"
              :items="filteredData"
              :loading="loadingProjects"
              :obseleteList="obseleteData"
              design-type="DRW"
              @loadDesigns="loadDesigns"
              @obsolete="obsolete"
            />
          </b-tab>

          <b-tab title="Software" @click="loadDesigns('FOR')">
            <tab-content
              :project="selectedNickname"
              :items="filteredData"
              :loading="loadingProjects"
              :obseleteList="obseleteData"
              design-type="FOR"
              @loadDesigns="loadDesigns"
              @obsolete="obsolete"
            />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
//import { GET_SCOPES } from "@/core/services/store/scope.module.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module.js";
import { GET_OUTSTANDING } from "@/core/services/store/outstanding.module.js";
import {
  GET_DETAIL_DL_DESIGN_LIST,
  GET_STATION,
  GET_SCOPE_BY_PROJECT,
  GET_STATUS
} from "@/core/services/store/dl.design.module.js";
import { GET_PROJECT_DETAIL } from "@/core/services/store/project.module.js";
// import { GET_STATION } from "../../../core/services/store/station.module";
import TabContent from "./detail/TabContent";
import { OBSOLETE_DL_DESIGN } from "../../../core/services/store/dl.design.module";

export default {
  name: "DetailDlDesign",
  components: {
    TabContent
  },
  data() {
    return {
      filter: {
        id_project: null,
        nickname: null,
        station: null,
        scope: null,
        jobdesk: ["design", "admin"],
        title: null,
        status: null
      },
      selectedNickname: {
        ownerName: null,
        projectName: null,
        status: null,
        contractNumber: null,
        code: null
      },
      options: {
        nicknames: [
          { value: null, text: "Choose Project" },
          { value: "1", text: "Makpa 1" },
          { value: "2", text: "Makpa 2" }
        ],
        stations: [
          { value: null, text: "Choose Station" },
          { value: "1", text: "LRS0123123" },
          { value: "2", text: "LRS0000001" }
        ],
        scopes: [
          { value: null, text: "Choose Scope" },
          { value: "1", text: "HMI" },
          { value: "2", text: "CHIEF" }
        ]
      },
      obseleteData: []
    };
  },
  computed: {
    ...mapState({
      errors: state => state.position.errors,
      dlDesigns: state => state.design.detailDlDesignList?.data?.dld,
      scopes: state => state.scope.scopes,
      status: state => state.status.status,
      outstandings: state => state.outstanding.outstanding?.data,
      projects: state => state.projectDetails.projectDetails?.data,
      stations: state => state.stations.stations.stations,
      loadingProjects: state => state.design.loading
    }),

    currentIdProject() {
      return this.filter.id_project;
    },

    currentNickname() {
      return this.filter.nickname;
    },

    currentDlDesigns() {
      return this.dlDesigns;
    },

    formattedScopeOptions() {
      let scopes = [];
      if (this.arrayMoreThanOne(this.scopes)) {
        scopes = this.scopes.map(scope => {
          return {
            value: scope.code,
            text: scope.name
          };
        });
      }

      return [{ value: null, text: "Choose Scope" }, ...scopes];
    },

    formatedStatusOptions() {
      let status = [];
      if (this.arrayMoreThanOne(this.status.data)) {
        status = this.status.data.map(status => {
          return {
            value: status.code,
            text: status.name
          };
        });
      }

      return [{ value: null, text: "Choose Status" }, ...status];
    },

    formattedProjectOptions() {
      let projects = [];
      if (this.arrayMoreThanOne(this.projects)) {
        projects = this.projects.map(project => {
          return {
            value: project.code,
            text: project.nickname
          };
        });
      }

      return [{ value: null, text: "Choose Project" }, ...projects];
    },

    formattedStationOptions() {
      let stations = [];
      if (this.arrayMoreThanOne(this.stations)) {
        stations = this.stations.map(project => {
          return {
            value: project.code,
            text: project.name
          };
        });
      }

      return [{ value: null, text: "Choose Station" }, ...stations];
    },

    filteredData() {
      return this.dlDesigns?.filter(dlDesign => {
        return (
          this.filterScope(dlDesign) &&
          this.filterStatus(dlDesign) &&
          this.filterStation(dlDesign) &&
          this.filterTitle(dlDesign)
        );
      });
    }
  },

  watch: {
    currentNickname(value) {
      if (!value) {
        Object.assign(this.selectedNickname, {
          ownerName: null,
          projectName: null,
          status: null,
          contractNumber: null,
          code: null
        });

        return;
      }

      const project = this.projects.find(
        project => project.code === this.filter.nickname
      );

      Object.assign(this.selectedNickname, {
        ownerName: project?.owner,
        projectName: project?.name,
        status: project?.status,
        contractNumber: project?.contract_no,
        ...project
      });
    },

    currentIdProject(value) {
      if (value) {
        this.getDlDesigns();
      }
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Detail Dl Design" }]);

    this.getScopes();

    this.getOutstandings();

    this.getProjects();

    var url_string = window.location.href;
    var url = new URL(url_string);
    var getNickName = url.searchParams.get("nickname");
    if (getNickName != null)
      this.filter.nickname = this.formattedProjectOptions.find(
        x => x.text == getNickName
      ).value;
  },

  methods: {
    filterScope(item) {
      if (this.filter.scope) {
        return this.filter.scope === item.scope?.code;
      } else {
        return true;
      }
    },

    filterStatus(item) {
      if (this.filter.status) {
        return this.filter.status === item.last_status?.code;
      } else {
        return true;
      }
    },

    filterStation(item) {
      if (this.filter.station) {
        return this.filter.station === item.station?.code;
      } else {
        return true;
      }
    },

    filterTitle(item) {
      if (this.filter.title) {
        return item.title
          .toUpperCase()
          ?.includes(this.filter.title.toUpperCase());
      } else {
        return true;
      }
    },

    loadDesigns(type) {
      if (!this.filter.nickname) {
        this.$bvToast.toast("please choose project first.", {
          title: "Warning",
          variant: "warning",
          solid: true
        });
      } else {
        this.getDlDesigns(type);
      }
    },

    getProjects() {
      this.$store.dispatch(GET_PROJECT_DETAIL, {
        jobdesk: "design",
        params: this.filter.jobdesk
      });
    },

    getDlDesigns(type) {
      this.$store.dispatch(GET_DETAIL_DL_DESIGN_LIST, {
        project_id: this.selectedNickname?.code,
        type_design: type
      });
    },

    getScopes() {
      if (!this.arrayMoreThanOne(this.scopes)) {
        this.$store.dispatch(GET_SCOPE_BY_PROJECT, this.filter);
      }
    },

    getStations(data) {
      this.$store.dispatch(GET_STATION, { id: data });
      this.$store.dispatch(GET_SCOPE_BY_PROJECT, { id: data });
      this.$store.dispatch(GET_STATUS);
      console.log("change project");
      
    },

    getOutstandings() {
      if (!this.arrayMoreThanOne(this.outstandings)) {
        this.$store.dispatch(GET_OUTSTANDING, this.filter);
      }
    },

    arrayMoreThanOne(array) {
      return array?.length > 0;
    },

    obsolete(item, type, i) {
      this.$store
        .dispatch(OBSOLETE_DL_DESIGN, {
          dld: [
            {
              id: item?.id,
              title: item?.title,
              last_status: item?.last_status,
              revision_version: item?.revision_version,
              version: item?.version,
              date_receive: item?.date_receive,
              delay: item?.delay,
              doc_room: item?.doc_room,
              server: item?.server,
              monitoring_asbuilt_status: item?.monitoring_asbuilt_status,
              return_item: item?.return_item,
              outstanding: item?.outstanding
            }
          ],
          type,
          project_id: this.selectedNickname?.code
        })
        .then(x => {
          this.obseleteData.push({ type, i, data: x.data.data });
          setTimeout(() => {
            this.obseleteData = this.obseleteData.filter(
              z => z.i != i && z.type != type
            );
          }, 1500);
        });
    }
  }
};
</script>

<style></style>
