export const exportExcelColumn = [
  {
    label: "Id (unchangeable)",
    field: "id",
    color: 'red',
  },
  {
    label: "Title (Changeable)",
    field: "title",
    color: 'red',
  },
  {
    label: "Numbering",
    field: "numbering",
    color: 'red',
  },
  {
    label: "Last Status (Changeable)",
    field: "last_status",
    color: 'red',
  },
  {
    label: "Revision Version (Changeable)",
    field: "revision_version",
    color: 'red',
  },
  {
    label: "Version (Changeable)",
    field: "version",
    color: 'red',
  },
  {
    label: "Date Receive (Changeable)",
    field: "date_receive",
    color: 'red',
  },
  {
    label: "Delay (Changeable)",
    field: "delay",
    color: 'red',
  },
  {
    label: "Doc Room (Changeable)",
    field: "doc_room",
    color: 'red',
  },
  {
    label: "Server",
    field: "server",
    color: 'red',
  },
  {
    label: "Monitoring Asbuilt Status (Changeable)",
    field: "monitoring_asbuilt_status",
    color: 'red',
  },
  {
    label: "Return Item (Changeable)",
    field: "return_item",
    color: 'red',
  },
  {
    label: "Outstanding (Changeable)",
    field: "outstanding",
    color: 'red',
  },
  {
    label: "Obselete",
    field: "obselete",
    color: 'red',
  },
  {
    label: "Hapus (Yes Or No)",
    field: "hapus",
    color: 'red',
  },
];
